"use strict";
//document.addEventListener('readystatechange', event => {
//if (event.target.readyState === "complete") {
export function initializeIndicatorHeader() {
    function indicatorHeader() {
        let $indicator = $(".indicator");
        if ($indicator.length === 0) {
            $indicator = $('<div class="indicator"></div>');
            $(".menu-container").append($indicator);
        }

        // Indikator erstellen und zum Menü-Container hinzufügen
        $(".menu-container").append($indicator);

        // Funktion, um den Indikator zu positionieren
        function positionIndicator($activeLink) {
            let linkOffset = $activeLink.offset();
            let containerOffset = $(".menu-container").offset();
            let linkWidth = $activeLink.width();

            $indicator.css({
                left: linkOffset.left - containerOffset.left,
                width: linkWidth,
            });
        }

        // Indikator beim Laden der Seite positionieren
        let $activeLink = $(".nav-item.nav-link.active");
        if ($activeLink.length) {
            positionIndicator($activeLink);
        }
    }

    indicatorHeader();
}

//} // complee load
//});
