"use strict";

//document.addEventListener('DOMContentLoaded', (event) => {
export function initializeCapsLockDetect() {
    $(document).ready(function () {
        $("#password").keypress(function (e) {
            let s = String.fromCharCode(e.which);
            if (
                (s.toUpperCase() === s &&
                    s.toLowerCase() !== s &&
                    !e.shiftKey) ||
                (s.toUpperCase() !== s && s.toLowerCase() === s && e.shiftKey)
            ) {
                // Wenn die Umschalttaste aktiviert ist, zeigen wir die Warnmeldung an.
                $("#caps-warning").removeClass("d-none");
            } else {
                // Wenn die Umschalttaste deaktiviert ist, verstecken wir die Warnmeldung.
                $("#caps-warning").addClass("d-none");
            }
        });
    });
    $(document).ready(function () {
        $("#password-confirm").keypress(function (e) {
            let s = String.fromCharCode(e.which);
            if (
                (s.toUpperCase() === s &&
                    s.toLowerCase() !== s &&
                    !e.shiftKey) ||
                (s.toUpperCase() !== s && s.toLowerCase() === s && e.shiftKey)
            ) {
                // Wenn die Umschalttaste aktiviert ist, zeigen wir die Warnmeldung an.
                $("#caps-warning-confirm").removeClass("d-none");
            } else {
                // Wenn die Umschalttaste deaktiviert ist, verstecken wir die Warnmeldung.
                $("#caps-warning-confirm").addClass("d-none");
            }
        });
    });
}

//});
