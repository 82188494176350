"use strict";

export function initializeSetImageDimensions() {
    const images = document.querySelectorAll(".auto-dimension");
    images.forEach((img) => {
        if (img.complete) {
            // Das Bild ist bereits geladen
            img.width = img.naturalWidth;
            img.height = img.naturalHeight;
        } else {
            // Das Bild wird noch geladen, also warten wir auf den load-Event
            img.onload = function () {
                img.width = this.naturalWidth;
                img.height = this.naturalHeight;
            };
        }
    });
}

// Funktion, die die Logik für das Laden von Bildern enthält
function updateLazyImages() {
    let lazyImages = [].slice.call(
        document.querySelectorAll("img[loading='lazy']"),
    );
    lazyImages.forEach(function (img) {
        img.onload = function () {
            img.classList.add("is-loaded");
        };

        if (img.complete && img.naturalHeight !== 0) {
            img.classList.add("is-loaded");
        }
    });
}

// MutationObserver-Konfiguration, spezifiziert was beobachtet wird
const config = { childList: true, subtree: true };

// Callback-Funktion, die aufgerufen wird, wenn Mutationen beobachtet werden
const callback = function (mutationsList, observer) {
    // Für jede Mutation
    for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
            updateLazyImages();
        }
    }
};

// Erstellung eines MutationObserver-Objekts mit der callback-Funktion
const observer = new MutationObserver(callback);

// Starten der Beobachtung des body-Elements und der Nachkommen auf Änderungen
observer.observe(document.body, config);

// Initialer Aufruf für die Seite
updateLazyImages();
