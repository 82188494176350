"use strict";

export function initializePasswordToggle() {
    let toggleButtons = document.querySelectorAll(".toggle-password");

    toggleButtons.forEach(function (toggleButton) {
        toggleButton.addEventListener("click", function () {
            let passwordField =
                this.parentElement.querySelector(".password-field");
            let passwordFieldType = passwordField.getAttribute("type");

            if (passwordFieldType === "password") {
                passwordField.setAttribute("type", "text");
                toggleButton.classList.remove("fa-eye");
                toggleButton.classList.add("fa-eye-slash");
            } else {
                passwordField.setAttribute("type", "password");
                toggleButton.classList.remove("fa-eye-slash");
                toggleButton.classList.add("fa-eye");
            }
        });
    });
}
