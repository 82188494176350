"use strict";

//document.addEventListener('DOMContentLoaded', (event) => {
export function initializeDataStyle() {
    $(document).ready(function () {
        document
            .querySelectorAll(".data-style[data-style]")
            .forEach(function (element) {
                const styleString = element.getAttribute("data-style");
                styleString.split(";").forEach(function (style) {
                    if (style) {
                        const [property, value] = style.split(":");
                        element.style[property.trim()] = value;
                    }
                });
            });
    });
}

//});
