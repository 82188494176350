import "./bootstrap";
//import "./elements/turbo-echo-stream-tag";
//import "./libs";
// import jQuery UI
//import 'jquery-ui/dist/jquery-ui';
import "jquery-ui/ui/version";
import "jquery-ui/ui/widget";
import "jquery-ui/ui/data";
//import 'jquery-ui/ui/disable-selection';
//import 'jquery-ui/ui/ie';
//import 'jquery-ui/ui/keycode';
//import 'jquery-ui/ui/labels';
import "jquery-ui/ui/plugin";
//import 'jquery-ui/ui/safe-active-element';
//import 'jquery-ui/ui/safe-blur';
import "jquery-ui/ui/scroll-parent";
//import 'jquery-ui/ui/focusable';
//import 'jquery-ui/ui/tabbable';
//import 'jquery-ui/ui/unique-id';
import "jquery-ui/ui/widgets/mouse";

import { initializeFormValidation } from "./panel/general/formValidationWithTabs.js";
import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import { initializeDataStyle } from "./panel/general/data_style";
import { initializePassCheckIndicator } from "./panel/general/password_check_indicator";
import { initializeCapsLockDetect } from "./panel/general/caps_lock_detect";
import { initializeIndicatorHeader } from "./panel/general/indicator_header";
import { initializeLinkDecoration } from "./panel/general/link_decoration";
import { initializeLoadingButton } from "./panel/general/loading_button";
import { initializePasswordToggle } from "./panel/general/password-toggle";
import { initializeSetLanguageMainDropdown } from "./panel/general/set_language_main_dropdown";
import { initializeSetImageDimensions } from "./panel/setImageDimensions_LazyImg";
import { initializeTooltip } from "./panel/general/tooltip";
// import pull to refresh
import PullToRefresh from "pulltorefreshjs";
// import SweetAlert2
import Swal from "sweetalert2";
import $ from "jquery";

/*import {onCLS, onFID, onLCP} from 'web-vitals';
onCLS(console.log);
onFID(console.log);
onLCP(console.log);*/

//import 'jquery-ui/ui/widgets/draggable';
//import 'jquery-ui/ui/widgets/droppable';
//import 'jquery-ui/ui/widgets/resizable';
//import 'jquery-ui/ui/widgets/selectable';
//import 'jquery-ui/ui/widgets/sortable';
//import 'jquery-ui/ui/widgets/accordion';
//import 'jquery-ui/ui/widgets/autocomplete';
//import 'jquery-ui/ui/widgets/button';
//import 'jquery-ui/ui/widgets/checkboxradio';
//import 'jquery-ui/ui/widgets/controlgroup';
//import 'jquery-ui/ui/widgets/datepicker';
//import 'jquery-ui/ui/widgets/dialog';
//import 'jquery-ui/ui/widgets/menu';
//import 'jquery-ui/ui/widgets/progressbar';
//import 'jquery-ui/ui/widgets/selectmenu';
//import 'jquery-ui/ui/widgets/slider';
//import 'jquery-ui/ui/widgets/spinner';
//import 'jquery-ui/ui/widgets/tabs';
//import 'jquery-ui/ui/widgets/tooltip';

/*import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import Clipboard from '@ryangjchandler/alpine-clipboard'

Alpine.plugin(Clipboard)

Alpine.start();

Livewire.start();*/

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//import './components/Example';
const isExtraInput = window.location.pathname.startsWith("/register");
if (isExtraInput) {
    import("./panel/general/extra_input");
}
import("./panel/general/dropdown_menu_main");
import("./panel/general/dropdown_menu_mobile");
import("./panel/general/dark_light");
import("./panel/general/change_font_size");
import("./panel/general/set_language_login");

const isSelect2ColorChange =
    window.location.pathname.startsWith("/settings/company/") ||
    window.location.pathname.startsWith("/settings/qualifications/") ||
    window.location.pathname.startsWith("/employee/");
if (isSelect2ColorChange) {
    // Dynamisches Importieren des Skripts nur auf den entsprechenden Seiten
    import("./panel/Select2_Color_Change");
}

const isSettingsRolesPath =
    window.location.pathname.startsWith("/settings/roles/");
if (isSettingsRolesPath) {
    Promise.all([
        import("jquery-ui/ui/widgets/droppable"),
        import("jquery-ui/ui/widgets/sortable"),
    ]).then(() => {
        // Widgets sind geladen; jetzt können Sie Ihre eigenen Skripte laden, die möglicherweise von diesen Widgets abhängig sind
        import("./panel/Settings_Create_Roles_Qualifications");
        import("./panel/Settings_Roles");
    });
}
const isSettingsQualificationsPath = window.location.pathname.startsWith(
    "/settings/qualifications/",
);
if (isSettingsQualificationsPath) {
    Promise.all([
        import("jquery-ui/ui/widgets/droppable"),
        import("jquery-ui/ui/widgets/sortable"),
    ]).then(() => {
        // Widgets sind geladen; jetzt können Sie Ihre eigenen Skripte laden, die möglicherweise von diesen Widgets abhängig sind
        import("./panel/Settings_Create_Roles_Qualifications");
        import("./panel/Settings_Qualifications");
    });
}
const isSettingsContractsPath = window.location.pathname.startsWith(
    "/settings/contracts/",
);
if (isSettingsContractsPath) {
    Promise.all([
        import("jquery-ui/ui/widgets/droppable"),
        import("jquery-ui/ui/widgets/sortable"),
    ]).then(() => {
        // Widgets sind geladen; jetzt können Sie Ihre eigenen Skripte laden, die möglicherweise von diesen Widgets abhängig sind
        import("./panel/Settings_Create_Roles_Qualifications");
        import("./panel/Settings_Qualifications");
    });
}
const isFormValidationTab = window.location.pathname.startsWith("/employee/");

if (isFormValidationTab) {
    window.initializeFormValidation = initializeFormValidation;
}

// import dynamisch Swiper
const isSwiper = window.location.pathname.startsWith("/employee/");
if (isSwiper) {
    document.addEventListener("DOMContentLoaded", () => {
        Swiper.use([Navigation]);

        let swiperContainer = document.querySelector(".swiper-container");

        swiperContainer.addEventListener("click", function () {
            // Schließe alle Select2 Dropdowns, wenn innerhalb des Swiper-Containers geklickt wird
            $(".select2").each(function () {
                if ($(this).data("select2")) {
                    $(this).select2("close");
                }
            });
        });

        // Füge ein Click Event zu jedem Slide hinzu
        swiperContainer.addEventListener("click", function (e) {
            // Überprüfe, ob das geklickte Element ein Swiper-Slide ist
            let clickedSlide = e.target.closest(".swiper-slide");
            if (clickedSlide) {
                // Finde den Index des geklickten Slides
                let clickedSlideIndex = Array.from(
                    swiperContainer.querySelectorAll(".swiper-slide"),
                ).indexOf(clickedSlide);

                // Überprüfe, ob wir auf einem Mobilgerät sind (Sie können die Breite anpassen, um zu definieren, was "mobil" bedeutet)
                const isMobile = window.innerWidth <= 768; // oder eine andere Zahl, die für Mobilgeräte steht

                // Berechne den Index, zu dem Swiper navigieren soll
                let newIndex;
                if (isMobile) {
                    newIndex = clickedSlideIndex; // Auf Mobilgeräten, zeige den geklickten Slide als ersten
                } else {
                    newIndex = clickedSlideIndex - 1; // Auf Desktop, zeige den geklickten Slide als zweiten
                    newIndex = Math.max(newIndex, 0); // Stelle sicher, dass der neue Index nicht kleiner als 0 ist
                }
                // Springe zu dem neuen Index
                window.swiper.slideTo(newIndex, 300); // Die 300 ist die Dauer der Animation in ms
            }
        });

        window.swiper = new Swiper(".swiper-container", {
            slidesPerView: "auto",
            slidesPerGroup: 1, // Anzahl der Slides, die pro Navigationsschritt verschoben werden
            spaceBetween: 10, // Abstand zwischen den Tabs
            freeMode: true,
            //freeModeMomentum: true, // Erlaubt den Slides, nach dem Loslassen weiterzugleiten
            //freeModeMomentumBounce: true, // Erlaubt den Slides, am Ende sanft einzurasten
            resistance: true, // Widerstand gegen das Ziehen über die Ränder hinaus aktivieren
            resistanceRatio: 0, // Verhindert das Ziehen über die Ränder hinaus komplett
            effect: "fade",
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            on: {
                init: function () {
                    // Prüfe die Sichtbarkeit des "Zurück"-Buttons beim Initialisieren
                    togglePrevButtonVisibility(this);
                    toggleNextButtonVisibility(this);
                    setTimeout(() => adjustNextButtonVisibility(this), 100);
                },
                slideChange: function () {
                    adjustNextButtonVisibility(this);
                    togglePrevButtonVisibility(this);
                    toggleNextButtonVisibility(this);
                    adjustContainerPadding();
                },
            },
        });

        // Checkt ob das Select2-Element sich geschloßen hat und trigger die Funktion
        $(document).on("select2:close", function (e) {
            // Führen Sie hier die Aktion aus, die ausgeführt werden soll, wenn das Select2-Element geschlossen wird
            setTimeout(() => adjustNextButtonVisibility(window.swiper), 100);
        });

        // blendet den rechten pfeil aus, wenn der swiper am Ende ist
        function toggleNextButtonVisibility(swiper) {
            let nextButton = document.querySelector(".swiper-button-next");
            if (swiper.isEnd) {
                nextButton.style.transition =
                    "opacity 0.3s ease-out, visibility 0s 0.3s"; // Sanfter Übergang für opacity, verzögerte Anwendung von visibility
                nextButton.style.opacity = "0";
                nextButton.style.visibility = "hidden";
            } else {
                nextButton.style.transition =
                    "opacity 0.3s ease-out, visibility 0s 0s"; // Sanfter Übergang für opacity, sofortige Anwendung von visibility
                nextButton.style.opacity = "1";
                nextButton.style.visibility = "visible";
            }
        }

        // blendet den linken pfeil aus, wenn der swiper am anfang ist
        function togglePrevButtonVisibility(swiper) {
            let prevButton = document.querySelector(".swiper-button-prev");
            if (swiper.isBeginning) {
                prevButton.style.transition =
                    "opacity 0.3s ease-out, visibility 0s 0.3s"; // Sanfter Übergang für opacity, verzögerte Anwendung von visibility
                prevButton.style.opacity = "0";
                prevButton.style.visibility = "hidden";
            } else {
                prevButton.style.transition =
                    "opacity 0.3s ease-out, visibility 0s 0s"; // Sanfter Übergang für opacity, sofortige Anwendung von visibility
                prevButton.style.opacity = "1";
                prevButton.style.visibility = "visible";
            }
        }

        // Funktion zum Anpassen des Paddings des Swiper-Containers
        function adjustContainerPadding() {
            // Überprüfen Sie, ob der Swiper am Anfang ist
            if (swiper.isBeginning) {
                swiperContainer.style.transition = "padding 0.3s ease-out"; // Sanfter Übergang
                swiperContainer.style.padding = "0px 35px 0px 0px"; // Kein Padding auf der linken Seite
            } else if (swiper.isEnd) {
                swiperContainer.style.transition = "padding 0.3s ease-out"; // Sanfter Übergang
                swiperContainer.style.padding = "0px px 0px 35px"; // Kein Padding auf der rechten Seite
            } else {
                swiperContainer.style.transition = "padding 0.3s ease-out"; // Sanfter Übergang
                swiperContainer.style.padding = "0px 35px 0px 35px"; // Standard Padding
            }
        }

        // versteckt den rechten pfeil, wenn die gesamtbreite der slides kleiner ist als die breite des containers
        function adjustNextButtonVisibility(swiper) {
            let nextButton = document.querySelector(".swiper-button-next");
            let slidesWidth = 0;

            // Berechne die Gesamtbreite aller Slides
            swiper.slides.forEach(function (slide) {
                slidesWidth +=
                    slide.offsetWidth +
                    parseInt(window.getComputedStyle(slide).marginRight, 10);
            });

            // Überprüfe, ob die Gesamtbreite der Slides geringer ist als die Breite des Swiper-Containers
            let containerWidth =
                document.querySelector(".swiper-container").offsetWidth; // Stelle sicher, dass du den Container über swiper.container[0] referenzierst

            // Überprüfung, ob der aktuelle Index gleich der Anzahl der Slides minus 1 ist
            let isAtEnd = swiper.activeIndex === swiper.slides.length - 1;

            if (slidesWidth <= containerWidth || isAtEnd) {
                // Falls die Gesamtbreite der Slides kleiner oder gleich der Containerbreite ist ODER der Swiper am Ende ist
                nextButton.style.transition =
                    "opacity 0.3s ease-out, visibility 0s 0.3s"; // Sanfter Übergang für opacity, verzögerte Anwendung von visibility
                nextButton.style.opacity = "0";
                nextButton.style.visibility = "hidden";
            } else {
                // Ansonsten mache den "Weiter"-Button sichtbar
                nextButton.style.transition =
                    "opacity 0.3s ease-out, visibility 0s 0s"; // Sanfter Übergang für opacity, sofortige Anwendung von visibility
                nextButton.style.opacity = "1";
                nextButton.style.visibility = "visible";
            }
        }

        // Erste Anpassung beim Laden
        adjustContainerPadding();
    });
}

document.addEventListener("DOMContentLoaded", (event) => {
    initializeDataStyle();
    initializePassCheckIndicator();
    initializeCapsLockDetect();
    initializeIndicatorHeader();
    initializeLinkDecoration();
    initializeLoadingButton();
    initializePasswordToggle();
    initializeSetLanguageMainDropdown();
    initializeSetImageDimensions();
    initializeTooltip();
});

document.addEventListener("DOMContentLoaded", () => {
    const ptr = PullToRefresh.init({
        mainElement: ".content-scrollable", // Dein scrollbares Element
        shouldPullToRefresh: function () {
            const scrollable = document.querySelector(".content-scrollable");
            const isModalOpen = document.body.classList.contains("modal-open");

            // Trigger nur, wenn Scroll-Position am Anfang ist UND kein Modal geöffnet ist
            return scrollable.scrollTop === 0 && !isModalOpen;
        },
        onRefresh() {
            window.location.reload();
        },
    });
});

// import dynamisch Quill
const isQuill = window.location.pathname.startsWith("/employee/");
if (isQuill) {
    document.addEventListener("DOMContentLoaded", () => {
        // Prüfen, ob das Element existiert, bevor Quill geladen wird
        if (document.querySelector("#editor")) {
            // Beobachten Sie die Änderungen am DOM, um zu erkennen, wann der Editor geladen ist für DevTools 100%
            let observer = new MutationObserver(function (mutations) {
                if (document.querySelector(".ql-editor")) {
                    // Helferfunktion, um ARIA-Label zu den Schaltflächen hinzuzufügen
                    function addAriaLabel(buttonClass, ariaLabel) {
                        let buttons = document.querySelectorAll(buttonClass);
                        buttons.forEach(function (button) {
                            button.setAttribute("aria-label", ariaLabel);
                        });
                    }

                    // Fügen Sie hier für jeden Schaltflächentyp ein ARIA-Label hinzu
                    addAriaLabel("button.ql-bold", "Bold");
                    addAriaLabel("button.ql-italic", "Italic");
                    addAriaLabel("button.ql-underline", "Underline");
                    addAriaLabel("button.ql-strike", "Strikethrough");
                    addAriaLabel("button.ql-blockquote", "Blockquote");
                    addAriaLabel("button.ql-code-block", "Code Block");
                    addAriaLabel('button.ql-header[value="1"]', "Heading 1");
                    addAriaLabel('button.ql-header[value="2"]', "Heading 2");
                    addAriaLabel(
                        'button.ql-list[value="ordered"]',
                        "Ordered List",
                    );
                    addAriaLabel(
                        'button.ql-list[value="bullet"]',
                        "Bullet List",
                    );
                    addAriaLabel('button.ql-script[value="sub"]', "Subscript");
                    addAriaLabel(
                        'button.ql-script[value="super"]',
                        "Superscript",
                    );
                    addAriaLabel(
                        'button.ql-indent[value="-1"]',
                        "Decrease Indent",
                    );
                    addAriaLabel(
                        'button.ql-indent[value="+1"]',
                        "Increase Indent",
                    );
                    addAriaLabel(
                        'button.ql-direction[value="rtl"]',
                        "Right-to-Left",
                    );
                    addAriaLabel("button.ql-clean", "Clear Formatting");

                    // Funktion zum Hinzufügen von ARIA-Labels
                    function addAriaLabelToPicker(pickerClass, ariaLabel) {
                        let pickers = document.querySelectorAll(pickerClass);
                        pickers.forEach(function (picker) {
                            picker.setAttribute("aria-label", ariaLabel);
                        });
                    }

                    // Hinzufügen von ARIA-Labels zu den verschiedenen Pickern
                    addAriaLabelToPicker(
                        ".ql-size .ql-picker-label",
                        "Select font size",
                    );
                    addAriaLabelToPicker(
                        ".ql-header .ql-picker-label",
                        "Select header",
                    );
                    addAriaLabelToPicker(
                        ".ql-color .ql-picker-label",
                        "Select text color",
                    );
                    addAriaLabelToPicker(
                        ".ql-background .ql-picker-label",
                        "Select background color",
                    );
                    addAriaLabelToPicker(
                        ".ql-font .ql-picker-label",
                        "Select font",
                    );
                    addAriaLabelToPicker(
                        ".ql-align .ql-picker-label",
                        "Select text alignment",
                    );

                    // Funktion zum Hinzufügen von href='#' zu bestimmten Elementen
                    function addHrefToLinks() {
                        let links = document.querySelectorAll(
                            "a.ql-action, a.ql-remove",
                        );
                        links.forEach(function (link) {
                            link.setAttribute("href", "#");
                        });
                    }

                    // Rufen Sie die Funktion auf, um href zu den Links hinzuzufügen
                    addHrefToLinks();

                    observer.disconnect(); // Beenden des Observers, sobald der Editor geladen ist
                }
            });

            observer.observe(document.body, { childList: true, subtree: true });

            import("./panel/Quill_init");

            // kaschiert den editor paragraph beim laden
            setTimeout(function () {
                const editorParagraph = document.querySelector("#editor p");
                if (editorParagraph) {
                    editorParagraph.style.visibility = "visible";
                }
            }, 500); // Verzögerung von 500 Millisekunden

            // Zeigt den editor header beim klicken
            setTimeout(function () {
                const editorElement = document.querySelector("#editor");
                const toolbarElement = document.querySelector(
                    ".ql-toolbar.ql-snow",
                );

                if (editorElement && toolbarElement) {
                    editorElement.addEventListener("click", function () {
                        toolbarElement.style.visibility = "visible";
                        toolbarElement.style.opacity = "1";
                    });
                }
            }, 500); // Verzögerung von 500 Millisekunden

            function setCursorToEnd(editorElement) {
                const range = document.createRange(); // Erstellt ein neues Range-Objekt
                const selection = window.getSelection(); // Holt das aktuelle Selection-Objekt
                range.selectNodeContents(editorElement); // Wählt den gesamten Inhalt des Elements aus
                range.collapse(false); // Kollabiert den Bereich zum Ende (false = Ende des Bereichs)
                selection.removeAllRanges(); // Entfernt alle bestehenden Bereiche aus der Auswahl
                selection.addRange(range); // Fügt den neuen Bereich zur Auswahl hinzu
            }

            // Zeigt den editor header beim klicken auf das label und setzt den cursor am ende
            setTimeout(function () {
                let label = document.querySelector('label[for="editorLabel"]');
                let editorDiv = document.getElementById("editor");

                if (label && editorDiv) {
                    label.addEventListener("click", function () {
                        document.querySelector(".ql-editor").click();
                        setCursorToEnd(document.querySelector(".ql-editor"));
                        document.querySelector(".ql-editor").focus();
                    });
                }
            }, 500); // Verzögerung von 500 Millisekunden

            // aktiviert den header wenn mit TAB gegangen wird und setzt den cursor am ende
            setTimeout(function () {
                document
                    .querySelector(".ql-editor")
                    .addEventListener("focus", function (event) {
                        document.querySelector(".ql-editor").click();
                        setCursorToEnd(document.querySelector(".ql-editor"));
                    });
            }, 500); // Verzögerung von 500 Millisekunden
        }
    });
}

// lightbox
// Überprüfen, ob der Pfad mit '/employee' oder '/settings/company' beginnt
const shouldLoadLightbox =
    window.location.pathname.startsWith("/employee") ||
    window.location.pathname.startsWith("/settings/company");
if (shouldLoadLightbox) {
    import("lightbox2").then((lightboxModule) => {
        // Zugriff auf das default Exportobjekt von Lightbox2
        const lightbox = lightboxModule.default;

        // Stellen Sie sicher, dass Lightbox erfolgreich geladen wurde
        if (lightbox) {
            // Die Lightbox der globalen Window-Variable zuweisen, falls notwendig
            window.lightbox = lightbox;

            // Konfigurieren der Lightbox, sobald das Dokument bereit ist
            $(document).ready(function () {
                lightbox.option({
                    resizeDuration: 200,
                    wrapAround: false,
                });
            });
        }
    });
}

window.Swal = Swal;
window.baseSwal = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    showCloseButton: true,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    showClass: {
        popup: "animate__animated animate__faster animate__fadeInDown", // Animate.css Klasse für das Einblenden
    },
    hideClass: {
        popup: "animate__animated animate__faster animate__fadeOutUp", // Animate.css Klasse für das Ausblenden
    },
});
