"use strict";

export function initializeLinkDecoration() {
    $(document).ready(function () {
        $(".link").hover(
            function () {
                $(this).find("::after").css("width", "100%");
            },
            function () {
                $(this).find("::after").css("width", "0");
            },
        );
    });
}
