"use strict";

export function initializePassCheckIndicator() {
    let passwordInput = document.getElementById("password");

    if (!passwordInput) {
        return;
    }

    passwordInput.addEventListener("input", function () {
        let value = passwordInput.value;
        let lengthCheck = document.getElementById("length-check");
        let caseCheck = document.getElementById("case-check");
        let numberOrSpecialCharCheck = document.getElementById(
            "number-special-char-check",
        );

        if (!lengthCheck || !caseCheck || !numberOrSpecialCharCheck) {
            return;
        }

        // Überprüfung der Länge
        if (value.length >= 8) {
            lengthCheck
                .querySelector("i")
                .classList.replace("text-muted", "text-success");
        } else {
            lengthCheck
                .querySelector("i")
                .classList.replace("text-success", "text-muted");
        }

        // Überprüfung auf Groß- und Kleinbuchstaben
        if (/[A-Z]/.test(value) && /[a-z]/.test(value)) {
            caseCheck
                .querySelector("i")
                .classList.replace("text-muted", "text-success");
        } else {
            caseCheck
                .querySelector("i")
                .classList.replace("text-success", "text-muted");
        }

        // Überprüfung auf mindestens eine Zahl oder ein Sonderzeichen
        if (/[0-9]/.test(value) || /[@$!%*#?&.,-]/.test(value)) {
            numberOrSpecialCharCheck
                .querySelector("i")
                .classList.replace("text-muted", "text-success");
        } else {
            numberOrSpecialCharCheck
                .querySelector("i")
                .classList.replace("text-success", "text-muted");
        }
    });

    let strengthBar = document.getElementById("password-strength-bar");

    passwordInput.addEventListener("input", function () {
        let value = passwordInput.value;
        let strength = 0;

        if (!strengthBar) {
            return;
        }

        // Prüfen der verschiedenen Kriterien und Erhöhen der Stärke
        if (value.length >= 8) {
            strength += 1;
        }
        if (/[A-Z]/.test(value) && /[a-z]/.test(value)) {
            strength += 1;
        }
        if (/[0-9]/.test(value) || /[@$!%*#?&.,-]/.test(value)) {
            strength += 1;
        }

        // Aktualisieren der Breite und Farbe der Stärkeleiste
        switch (strength) {
            case 0:
                strengthBar.style.width = "0%";
                strengthBar.style.backgroundColor = "#ddd";
                break;
            case 1:
                strengthBar.style.width = "33%";
                strengthBar.style.backgroundColor = "red";
                break;
            case 2:
                strengthBar.style.width = "66%";
                strengthBar.style.backgroundColor = "yellow";
                break;
            case 3:
                strengthBar.style.width = "100%";
                strengthBar.style.backgroundColor = "green";
                break;
        }
    });
}
