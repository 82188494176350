"use strict";

export function initializeFormValidation(formId, submitButtonId) {
    let shouldSubmit = true;

    $(`#${formId}`).on("submit", function (e) {
        if (!shouldSubmit) {
            e.preventDefault();
            return;
        }

        let invalidFound = false;

        // Iterieren durch jede .tab-pane, um das erste ungültige Feld zu finden
        $(".tab-pane").each(function () {
            let tabPane = $(this);
            let requiredInputs = tabPane.find(
                "input:required, select:required, textarea:required",
            );
            let invalidInputs = requiredInputs.filter(function () {
                return !this.checkValidity();
            });

            if (invalidInputs.length > 0) {
                e.preventDefault();
                invalidFound = true;
                shouldSubmit = false;

                // Öffne den Tab mit dem ungültigen Feld
                let tabId = tabPane.attr("id");
                openTab(tabId);

                // Finde den Index des Swiper-Slides, der zum geöffneten Tab gehört
                let swiperIndex = $('.nav-link-swiper[href="#' + tabId + '"]')
                    .parent(".swiper-slide")
                    .index();
                window.swiper.slideTo(swiperIndex);

                // Setze den Fokus auf das erste ungültige Feld
                invalidInputs.first().focus();

                return false; // Beendet die .each-Schleife
            }
        });

        if (!invalidFound) {
            shouldSubmit = true;
        }
    });

    $(`#${submitButtonId}`).on("click", function () {
        shouldSubmit = true;
        $(`#${formId}`).submit();
    });
}

function openTab(tabId) {
    $(".tab-pane").removeClass("show active");
    $(".nav-link-swiper").removeClass("active");
    $("#" + tabId)
        .addClass("show active")
        .css("opacity", "1");
    $('.nav-link-swiper[href="#' + tabId + '"]').addClass("active");
}
