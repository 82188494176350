"use strict";

export function initializeLoadingButton() {
    document.querySelectorAll(".loading-button").forEach(function (button) {
        button.addEventListener("click", function () {
            let spinner = this.querySelector(".spinner-border");
            spinner.classList.remove("d-none");

            // Ihr AJAX-Call oder ähnliches hier

            // Optional: Setzen Sie den Button nach dem Abschluss zurück
            setTimeout(function () {
                spinner.classList.add("d-none");
            }, 5000); // Beispiel: 2 Sekunden Verzögerung
        });
    });
}
