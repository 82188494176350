// Importiere jQuery und mache es global verfügbar
import $ from "jquery";
import * as Popper from "@popperjs/core";
/*
import('@popperjs/core').then(Popper => {
    window.Popper = Popper;
});
*/
// Importiere Bootstrap's JavaScript und mache es global verfügbar
import * as bootstrap from "bootstrap";
// Importiere Select2 und mache es global verfügbar + Optionen setzen
import select2 from "select2";
import axios from "axios";

window.jQuery = window.$ = $;


// Importiere Popper.js und mache es global verfügbar

window.Popper = Popper;
//import 'bootstrap';
window.bootstrap = bootstrap;

select2($);
$(document).ready(function () {
    // Lesen des Inhalts des meta-Tags
    let noResultsMessage = $('meta[name="no_results_found"]').attr('content');

    // Setzen der Select2-Konfiguration
    $.fn.select2.defaults.set('minimumResultsForSearch', Infinity);
    $.fn.select2.defaults.set("theme", "bootstrap-5");
    $.fn.select2.defaults.set("language", {
        noResults: function () {
            return noResultsMessage;
        }
    });


    // Damit wird ein aria-label für alle select2-Container gesetzt damit LightHouse nicht meckert für select2 multiple
    $(document).ready(function () {
        $(".select2-search__field").each(function () {
            let select_name = "select2-search__field_" + jQuery(this).attr("aria-labelledby");
            // add attribute ID = "select2-search__field_" + its label id
            $(this).attr("id", select_name);
            // find parent <li> and add label for="select2-search__field_" + its label id
            $(this).closest("li.select2-search").append('<label for="' + select_name + '" class="hide">TEST</label></div>');
        });
    });

    // fix damit die buttons richtig gerändert werden
    // Event-Handler für das Bevorstehende Öffnen einer select2-Box mit Delegierung
    $(document).on('select2:opening', '.select2', function (e) {
        // Schließen Sie alle anderen select2 Dropdowns, bevor die neue geöffnet wird
        $('.select2').not(this).each(function () {
            if ($(this).data('select2')) {
                $(this).select2('close');
            }
        });
    });

    // Globaler Event-Listener für alle Select2-Elemente
    $(document).on('change', '.select2', function () {
        // Ihr Code für das 'change'-Event
        let currentTheme = $('body').attr('data-bs-theme');
        if (currentTheme === 'dark') {
            $('body').attr('data-bs-theme', 'light').attr('data-bs-theme', 'dark');
        } else {
            $('body').attr('data-bs-theme', 'dark').attr('data-bs-theme', 'light');
        }
    });

    // Funktion zum Setzen der aria-labels für alle Select2-Container
    function setAriaLabelsForSelect2() {
        $('.select2-container').each(function () {
            let id = $(this).prev('select').attr('id');
            let label = $('label[for="' + id + '"]').text();
            $(this).find('.select2-selection__rendered').attr('aria-label', label);
        });
    }

    // Funktion zum Anpassen des Titels des 'Clear'-Buttons
    let clearAllElementsText = $('meta[name="clear_all_elements"]').attr('content');

    function customizeClearTitle() {
        // Finde alle Elemente mit der Klasse 'select2-selection__clear'
        $(".select2-selection__clear").each(function () {
            // Ändere das 'title'-Attribut
            $(this).attr("title", clearAllElementsText);
        });
    }

    customizeClearTitle();

    // Funktion zum Setzen des Titels des 'Remove'-Buttons
    let removeItemMessage = $('meta[name="remove_item"]').attr('content');

    function setRemoveItemTitle() {
        $(".select2-selection__choice__remove").each(function () {
            $(this).attr("title", removeItemMessage);
        });
    }

    setRemoveItemTitle();

    // MutationObserver zur Überwachung von Änderungen im DOM
    let observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            // Setze ARIA-Labels für Select2, wenn neue Knoten hinzugefügt werden
            if (mutation.addedNodes.length > 0) {
                setAriaLabelsForSelect2();
                customizeClearTitle();
            }

            // Iteriere über jeden hinzugefügten Knoten in dieser Mutation
            mutation.addedNodes.forEach(function (node) {
                // Überprüfe, ob der hinzugefügte Knoten ein Element ist und die spezifische Klasse enthält
                if (node.nodeType === 1 && $(node).find(".select2-selection__choice__remove").length > 0) {
                    setRemoveItemTitle();
                }
                // Da das hinzugefügte Element selbst oder eines seiner Kinder die Klasse enthalten könnte,
                // prüfe auch die Kinder des Knotens
                if (node.nodeType === 1 && $(node).closest(".select2-selection__choice__remove").length > 0) {
                    setRemoveItemTitle();
                }
            });
        });
    });

    // Konfiguration des Observers: Beobachte das Hinzufügen von Kindknoten
    let config = {childList: true, subtree: true};
    observer.observe(document.body, config);

});


/*
import('bootstrap').then(bootstrap => {
    window.bootstrap = bootstrap;
});
*/
/*
// Importiere Ant Design
import * as antd from 'antd';
// Mache Ant Design global verfügbar
window.antd = antd;
*/

/*
// import Smooth Scrollbar
import Scrollbar from 'smooth-scrollbar';
// Globale Verwendung von Smooth Scrollbar
window.Scrollbar = Scrollbar;
*/


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     wsHost: import.meta.env.VITE_PUSHER_HOST ?? `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });
