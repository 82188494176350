"use strict";

export function initializeSetLanguageMainDropdown() {
    (function () {
        if (!document.querySelector(".dropdown-language")) return;
        // das ist für die dropdown kontorolle
        // sprache
        let dropdownElement = document.querySelector(".dropdown-language");
        let dropdownMenu = dropdownElement.querySelector(".dropdown-menu");
        let dropdownToggle = dropdownElement.querySelector(".dropdown-toggle");

        dropdownElement.addEventListener("click", function (event) {
            if (dropdownMenu.contains(event.target)) {
                // Wenn innerhalb des Dropdown-Menüs geklickt wird, verhindern Sie das Schließen
                event.stopPropagation();
            } else if (dropdownToggle.contains(event.target)) {
                // Wenn auf den Toggle-Link geklickt wird
                if (dropdownMenu.classList.contains("show")) {
                    event.stopPropagation();
                    // Wenn das Dropdown-Menü geöffnet ist, schließen Sie es
                    dropdownMenu.classList.remove("show");
                } else {
                    // Wenn das Dropdown-Menü geschlossen ist, öffnen Sie es
                    dropdownMenu.classList.add("show");
                    event.stopPropagation();
                }
            }
        });

        // Wenn außerhalb des Dropdown-Elements geklickt wird, schließen Sie das Menü
        document.addEventListener("click", function () {
            if (dropdownMenu.classList.contains("show")) {
                dropdownMenu.classList.remove("show");
            }
        });

        // das ist für request
        // Wenn ein Sprachlink geklickt wird, aktualisieren Sie die Sprache
        let dropdownItems = document.querySelectorAll(
            ".language-dropdown-item",
        );

        dropdownItems.forEach((item) => {
            item.addEventListener("click", function () {
                let selectedLang = this.getAttribute("data-lang");

                // Senden Sie eine Anfrage an den Server, um die Sprache zu aktualisieren
                fetch(
                    `${document.querySelector('meta[name="set_language"]').content}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRF-TOKEN": `${document.querySelector('meta[name="csrf-token"]').content}`,
                        },
                        body: JSON.stringify({ language: selectedLang }),
                    },
                )
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            location.reload();
                        }
                    });
            });
        });
    })();
}
